import axios from "axios";
import md5 from 'js-md5';
import qs from "qs";
import { getToken ,removeToken} from "@/tools/auth"
import { Modal } from "view-ui-plus";
// import { commonStore } from '@/store'
// const useCommonStore = commonStore();
// 创建axios
// axios.defaults.headers['Content-Type'] = 'multipart/form-data; charset=UTF-8';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // timeout: 5000,
});


// console.log(process.env.VUE_APP_BASE_API,'process.env.VUE_APP_BASE_API')
// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    if (config.url.indexOf('api/auth/jwt/store/token') !== -1 || config.url.indexOf('api/auth/jwt/store/refresh') !== -1) {
        config.baseURL = process.env.VUE_APP_BASE_API_LOGIN;
    }
    if (getToken()) {
        config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // 加签
    if (config.method == "post") {
        const returnedTargetParams = Object.assign({}, config.params, { timespan: new Date().getTime() });
        const returnedTargetData = Object.assign({}, config.data, { timespan: new Date().getTime() });
        var isParams = Object.keys(returnedTargetData).length > 1 ? false : true;
        var info = Object.keys(returnedTargetData).length > 1 ? returnedTargetData : returnedTargetParams;
        // add sign
        info.sign = (function getSign(value) {
            var data = value || {};
            var keyList = Object.keys(data);
            var keySort = Array.prototype.sort.call(keyList, function (a, b) {
                for (var i = 0; i < a.length; i++) {
                    if (a.charCodeAt(i) == b.charCodeAt(i)) continue;
                    return a.charCodeAt(i) - b.charCodeAt(i);
                }
            });
            var sign = "";
            // console.log(keySort,'排序key')
            // 删除值为空的对应key
            let notNone = [];
            keySort.forEach((item) => {
                if (data[item] === null || data[item] === undefined || (data[item])?.toString().trim() === "") {
                    return
                } else {
                    notNone.push(item)
                }
            })
            // 拼接字符串
            notNone.forEach((element, index) => {
                if (notNone.length == 1 || notNone.length == (index + 1)) {
                    sign = sign + (element + '=' + (data[element]).toString().trim());
                } else {
                    sign = sign + (element + '=' + (data[element]).toString().trim()) + '&';
                }
            });
            // 重新排个序
            sign = sign.split("&").sort().join("&");
            var resultString = sign + 'chAgGolfm73o12pI9Cao';
            var md5Sign = md5(md5(resultString)).toLowerCase();
            return md5Sign;
        })(info);
        if (isParams) {
            config.params = info;
        } else {
            config.data = qs.stringify(info);
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use((response) => {
    if (response && response.data) {
        if (response.data.status === 200) {
            return response.data
        } else if (response.data.status === 40101) {
            // store.dispatch('FedLogOut').then(() => {
            //     location.href = '/'
            // })
            location.href = '/login';
            removeToken();
            // useCommonStore.FedLogOut().then(() => {
            //     location.href = '/home'
            // })
            // Modal.confirm({
            //     title:'ok',
            //     content:JSON.stringify(response)
            // });
        } else {
            Modal.confirm({
                title: '提示',
                content: response.data.message
            });
        }
    }
}, (error) => {
    if (error && error.response) {
        if (error.response.status === 40101) {
            // store.dispatch('FedLogOut').then(() => {
            //     location.href = '/'
            // })
        }
    } else {
        Modal.confirm({
            title: '错误提示',
            content: JSON.stringify(error.response.message)
        });
        // console.log(error, '连接到服务器失败')
    }
});

export default service;
console.log('axios.js')