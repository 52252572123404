import { defineStore ,createPinia} from 'pinia'
import { getToken, setToken, removeToken } from '@/tools/auth'
import { loginIn, refreshToken } from '@/api/login'
const store = createPinia()

// You can name the return value of `defineStore()` anything you want, but it's best to use the name of the store and surround it with `use` and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const commonStore = defineStore('common', {
    // other options...
    state: () => (
        {
            token: getToken(),
            refreshId: ''
        }),
    getters: {
        // doubleCount: (state) => state.Env * 2,
    },
    actions: {
        SET_REFRESHID(refreshId) {
            this.refreshId = refreshId
        },
        SET_TOKEN(token) {
            this.token = token;
            // console.log(this.token)
        },
        Login(username, password) {
            // console.log('login-token')
            return new Promise((resolve) => {
                loginIn(username, password).then(async (res) => {
                    if (res.status == 200) {
                        setToken(res.data);
                        this.SET_TOKEN(res.data);
                        // 定时刷新token;
                        let refreshInterId = setInterval(() => {
                            this.refresh();
                        }, 600000);
                        this.SET_REFRESHID(refreshInterId);
                        resolve(res)
                    }
                })

            })
        },
        async refresh() {
            const refreshResult = await refreshToken();
            if (refreshResult && refreshResult.status === 200) {
                setToken(refreshResult.data);
                this.SET_TOKEN(refreshResult.data);
            } else if (refreshResult && refreshResult.status === 40101) {
                this.FedLogOut().then(() => {
                    location.href = '/'
                })
            } else {
                setTimeout(() => {
                    this.refresh()
                }, 2000);
            }
        },
        FedLogOut() {
            return new Promise(resolve => {
                this.SET_TOKEN('');
                clearInterval(this.refreshId);
                this.SET_REFRESHID('')
                removeToken()
                resolve()
            })
        }
    },
})
export const pinia = store
