import { createApp } from 'vue'
import App from './App.vue'
// 配置
import router from "./router/index.js"
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import { pinia } from '@/store/index'

// 开发环境不载入 将请求转向https的meta标签
if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
    const addMeta = (httpEquiv, content) => {
        const meta = document.createElement('meta');
        meta.content = content;
        meta.httpEquiv = httpEquiv;
        document.getElementsByTagName('head')[0].appendChild(meta);
        // console.log('complete')
    };
    addMeta(
        'Content-Security-Policy',
        'upgrade-insecure-requests'
    );
}
// import { createPinia } from 'pinia'
// const pinia = createPinia()
console.log('main.js')
createApp(App).use(pinia).use(router).use(ViewUIPlus).mount('#app')
