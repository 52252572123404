import { createRouter, createWebHistory } from 'vue-router';
import { getToken } from '@/tools/auth'

// import { commonStore } from '@/store'
// const useCommonStore = commonStore();
const routes = [
    {
        path: "/",
        redirect: "/login",
        component: () => import("@/pages/loginPage.vue")
    },
    {
        path: "/login",
        component: () => import("@/pages/loginPage.vue")
    },
    {
        path: '/home', name: 'home', component: () => import('@/pages/home/indexPage.vue')
    },
    {
        path: '/shop', name: 'shopPage', component: () => import('@/pages/shop/shopPage.vue')
    }
    // { path: '/', redirect: '/user' },
    // {
    //     path: '/user', component: () => import('@/components/HelloWorld.vue'), children: [
    //         // 子路由路径开头不带斜杠/
    //         { path: 'money', component: () => import('@/components/python.vue') }
    //     ]
    // }
]


// createRouter替换旧版的new Router()
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    console.log('router.js')
    // console.log(to,'router',from)
    // if(to.path==='/login'){
    //     console.log(useCommonStore.token,'useCommonStore.token')
    // }
    if (to.path === '/login' && getToken()) {
        next({ path: '/home' })
    }
    next()
})


export default router;