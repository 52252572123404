import service from "../tools/axios/index"

export function refreshToken() {
    return service.request({
        method: 'get',
        url: '/api/auth/jwt/store/refresh'
    })
}

export function loginIn(username, password) {//,code,uuid
    const data = {
        username,
        password
    }
    return service.request({
        method: 'post',
        // request.js 启用了baseURL,所以加上  '/dev-api'
        url: '/api/auth/jwt/store/token',
        // headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8' },
        params: data
    })
}

