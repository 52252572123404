import Cookies from "js-cookie";

const TokenKey = "Admin-Token";


export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}


export function getList() {
    return Cookies.get('shopList');
}

export function setList(list) {
    return Cookies.set('shopList', JSON.stringify(list));
}

export function removeList() {
    return Cookies.remove('shopList');
}


export function setRemark(remark) {
    return Cookies.set('cartRemark', remark);
}
export function getRemark() {
    return Cookies.get('cartRemark');
}
export function removeRemark() {
    return Cookies.remove('cartRemark');
}
console.log('auth.js')